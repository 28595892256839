import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home"
import 'bootstrap/dist/css/bootstrap.min.css';
import Programma from "./pages/Programma";
import Ristorante from "./pages/Ristorante";
import Contatti from "./pages/Contatti";
import ListaViaggio from "./pages/ListaViaggio";
import Partecipazione from "./pages/Partecipazione";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/programma",
    element: <Programma />,
  },
  {
    path: "/ristorante",
    element: <Ristorante />,
  },
  {
    path: "/listaviaggio",
    element: <ListaViaggio />,
  },
  {
    path: "/contatti",
    element: <Contatti />,
  },
  {
    path: "/partecipazione",
    element: <Partecipazione />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);