import MyOffcanvas from "../components/MyOffcanvas";
import "../style.css"
import "../styles/listaviaggio.css"

export default function Ristorante() {
    return (
        <div>
            <div className="topbar">
                <h1 className="title">
                    Ristorante
                </h1>
                <div className="side-panel">
                    <MyOffcanvas/>
                </div>
            </div>
            <div className="listaviaggio">
                <div className="mb-5">Dopo il brindisi in patronato a Crespano, vi aspettiamo presso il Ristorante "Ai Gelsi" per continuare i festeggiamenti. Vi aspetta un abbondante buffet di benvenuto, il pranzo e una light dinner (vi consigliamo perciò di non abbuffarvi troppo al brindisi 😅). Di seguito vi lasciamo l'indirizzo del ristorante e qualche foto per rendervi l'idea.</div>
                <div className="linea"></div>
                <div className="mb-5">Via Ivo Lollini, 61, 31044 Montebelluna TV</div>
                <div className="linea"></div>
                <img src={require('../img/risto1.jpg')} className="immagini"/>
                <img src={require('../img/risto2.jpg')} className="immagini"/>
                <img src={require('../img/risto3.jpg')} className="immagini"/>
                <img src={require('../img/risto4.jpg')} className="immagini"/>
                <img src={require('../img/risto5.jpg')} className="immagini"/>
                <img src={require('../img/risto6.jpg')} className="immagini"/>
            </div>
        </div>
    );
}