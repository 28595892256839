import MyOffcanvas from "../components/MyOffcanvas";
import "../style.css";
import "../styles/contatti.css"

export default function Contatti() {
    return (
        <div>
            <div className="topbar">
                <h1 className="title">
                    Contatti
                </h1>
                <div className="side-panel">
                    <MyOffcanvas/>
                </div>
            </div>
            <div className="contatti">
                Per ogni dubbio non esitate a contattarci
                <div className="linea"></div>
                <div className="subsection">
                    <div className="nome">
                        Marco
                    </div>
                    <div className="info">
                    +39 3458407410
                    mrcgs95@gmail.com
                    </div>
                </div>
                <div className="linea"></div>
                <div className="subsection"> 
                    <div className="nome">
                        Giorgia
                    </div>
                    <div className="info">
                    +39 3496268126
                    capovillagiorgia@gmail.com
                    </div>
                </div>
            </div>
        </div>
    );
}