import MyOffcanvas from "../components/MyOffcanvas";
import "../style.css"

export default function Programma() {
    return (
        <div>
                <div className="side-panel">
                    <MyOffcanvas/>
                </div>
                <div className="modulo">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc3usvOi6q-PidB4azHxpxfCoKqqIEbQ7IT4-sM51SUutCkXA/viewform?embedded=true" width="640" height="1303" frameborder="0" marginheight="0" marginwidth="0" scrolling="no" >Caricamento…</iframe>
                </div>
       </div>
    );
}