import MyOffcanvas from "../components/MyOffcanvas";
import "../style.css"
import "../styles/listaviaggio.css"

export default function ListaViaggio() {
    return (
        <div>
            <div className="topbar">
                <h1 className="title">
                    Lista Viaggio
                </h1>
                <div className="side-panel">
                    <MyOffcanvas/>
                </div>
            </div>
            <div className="listaviaggio">
                <div className="mb-5">Il regalo più gradito sarà il vostro contributo per rendere indimenticabile il nostro viaggio di nozze.</div>
                <div className="mb-5">“Viaggiare è come sognare: la differenza è che non tutti al risveglio ricordano qualcosa, mentre ognuno conserva calda la memoria della meta da cui è tornato” Edgar Allan Poe</div>
                <div>Se desiderate contribuire, di seguito riportiamo le nostre coordinate:</div>
                <div>IBAN: IT52T0329601601000067521931</div>
                <div className="mb-5">Nome intestatario: Marco Gasparetto o Giorgia Capovilla</div>
                <div>Bali, Indonesia (02.09.24 – 16.09.24)</div>
                <div>Dubai, Emirati Arabi (17.09.24 – 21.09.24)</div>
                <div className="linea"></div>
                <div className="mb-2">BALI, Indonesia</div>
                <img src={require('../img/image1.jpeg')} className="immagini"/>
                <img src={require('../img/image2.jpeg')} className="immagini"/>
                <div className="linea"></div>
                <div className="mb-2">GILI ISLANDS, Indonesia</div>
                <img src={require('../img/image3.jpeg')} className="immagini"/>
                <img src={require('../img/image4.jpeg')} className="immagini"/>
                <div className="linea"></div>
                <div className="mb-2">DUBAI</div>
                <img src={require('../img/image5.jpeg')} className="immagini"/>
                <img src={require('../img/image6.jpeg')} className="immagini"/>
            </div>
        </div>
    );
}