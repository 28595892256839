import { Button } from "react-bootstrap";
import MyOffcanvas from "../components/MyOffcanvas";
import "../style.css"

export default function Home() {
    return (
        <div>
        <div className="topbar">
                <div className="title">
                    Marco e Giorgia
                    <div className="data">
                    31 Agosto 2024
                    </div>
                </div>
            <div className="side-panel">
                <MyOffcanvas/>
            </div>
            <div className="sfondo"/>
            <div className="overlay"/>
        </div>
        <div className="cisarai">
            <Button variant="light" size="lg" onClick={() => (window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSc3usvOi6q-PidB4azHxpxfCoKqqIEbQ7IT4-sM51SUutCkXA/viewform?usp=sf_link")}>
                Ci sarai?
            </Button>
        </div>
        </div>
    );
}