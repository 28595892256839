import { useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';

function MyOffcanvas() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <span class="material-symbols-outlined">
          menu
        </span>
      </Button>

      <Offcanvas show={show} placement='end' onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Marco e Giorgia</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            <ListGroupItem>
              <NavLink to="/" exact style={({ isActive }) => ({
                color: isActive ? 'grey' : 'black',
                textDecoration: 'none'
              })}>
                Home
              </NavLink>
            </ListGroupItem>
            <ListGroupItem>
              <NavLink to="/programma" style={({ isActive }) => ({
                color: isActive ? 'grey' : 'black',
                textDecoration: 'none'
              })}>
                Programma
              </NavLink>
            </ListGroupItem>
            <ListGroupItem>
              <NavLink to="/ristorante" style={({ isActive }) => ({
                color: isActive ? 'grey' : 'black',
                textDecoration: 'none'
              })}>
                Ristorante
              </NavLink>
            </ListGroupItem>
            <ListGroupItem>
              <NavLink to="/listaviaggio" style={({ isActive }) => ({
                color: isActive ? 'grey' : 'black',
                textDecoration: 'none'
              })}>
                Lista Viaggio
              </NavLink>
            </ListGroupItem>
            <ListGroupItem>
              <NavLink to="/contatti" style={({ isActive }) => ({
                color: isActive ? 'grey' : 'black',
                textDecoration: 'none'
              })}>
                Contatti
              </NavLink>
            </ListGroupItem>
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MyOffcanvas;